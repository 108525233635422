import React,{Component} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import {history} from "../../../shared/configure-store";
import photo1 from "../../../images/thumb1.jpeg";
import photo2 from "../../../images/section2.jpg";
import section from "../../../images/about/banner.jpg";
import top1 from "../../../images/about/1.jpeg";
import top2 from "../../../images/about/2.jpeg";
import top3 from "../../../images/about/4.jpeg";

import WatchLaterIcon from '@material-ui/icons/WatchLater';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

require('dotenv').config();

class Teams extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isOpen: false,
            images: [photo1,photo2],
            photoIndex: 0,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi Cemerlang - About Us';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };


    handleGo = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div>
                <section className="section-banner service" style={{backgroundImage: `url(${section})`}}>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-md-10'>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                <div className='content text-left'>
                                    <h1>ABOUT US</h1>
                                    <p>From the beginning, Abadi Cemerlang has committed to dedicating our experiences, skill, and innovation to the marine and insurance industries. Our experience in the marine surveyor and consultant industry inspires and motivates us to improve our service quality continually. Our quick response and reliable services should mean that our customers get the best result from our work which is skillfully executed to comply with our customers' demands.</p>
                                </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-content">
                    <div className='container'>
                        <div className='row mb-5'>
                            <div className='col-md-7 pr-lg-4'>
                                <h2>OUR VISION</h2>
                                <p className='mb-5'>"Become professional surveyor and consultant for marine industry. By sharing our passion and expertise to our employees to sharpen competitive edge in the very dynamic business situation. We aspire to leverage our multidiscipline knowledge to foster sustainable solutions and contribute to the growth of the marine industry in Southeast Asia especially in Indonesia. "</p>

                                <h2>OUR MISSION</h2>

                                <ol>
                                    <li>Increase productivity and quality of reports and approvals produced</li>
                                    <li>Provide the best service, whenever needed</li>
                                    <li>Provide sharing/training/workshop for clients/insurance regarding case examples & risk management</li>
                                    <li>Provide convenience for customers to monitor case status using our IT system</li>
                                    <li>Provide regular internal sharing/training so that existing human resources can quickly adapt and study existing cases</li>
                                    <li>Create a KPI system to evaluate performance objectively.</li>
                                    <li>Improve team bonding among employees</li>
                                </ol>
                            </div>
                            <div className='col-md-5'>
                                <img src={top2} className='img-fluid mb-3' />
                                <img src={top1} className='img-fluid mb-2' />

                            </div>
                        </div>
                        <div className='row justify-content-center item-vision'>
                            <div className='col-md-5'>
                                <img src={top3} className='img-fluid mb-2' />

                            </div>
                            <div className='col-md-7'>
                                <div className='row'>
                                    <div className='col-2 col-md-1'>
                                        <WatchLaterIcon />
                                    </div>
                                    <div className='col-10'>
                                        <h3>QUICK</h3>
                                        <p>Provide information quickly, both to internal and external parties.</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 col-md-1'>
                                        <TouchAppIcon />
                                    </div>
                                    <div className='col-10'>
                                        <h3>RIGHT</h3>
                                        <p>Able to provide accurate and high-quality information and advice.</p>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-2 col-md-1'>
                                        <ThumbUpIcon />
                                    </div>
                                    <div className='col-10'>
                                        <h3>RELIABLE</h3>
                                        <p>Able to provide reliable information to colleagues and able to produce complete and comprehensive survey reports, approvals, and consultations so that they can be relied on to resolve cases / claims faced by our clients.</p>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </div>

        )
    }
}

export default Teams;
