import React,{Component} from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import alif from "../../../images/team/alif.jpeg";
import faisal from "../../../images/team/faisal.jpeg";
import fauzi from "../../../images/team/Fauzi.jpeg";
import pudjo from "../../../images/team/pudjo.jpeg";
import rima from "../../../images/team/rima.jpeg";
import rizqia from "../../../images/team/kiki.jpeg";
import anwar from "../../../images/team/anwar.jpeg";
import tomy from "../../../images/team/tomy.jpg";
import section from "../../../images/team/banner.jpg";
import bilal from "../../../images/team/bilal.jpg";
import nathania from "../../../images/team/nathania.jpg";
import junor from "../../../images/team/junor.jpg";
import alifian from "../../../images/team/alifian-aji-santoso.jpg";
import timothy from "../../../images/team/timothy-henry.jpg";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

import {history} from "../../../shared/configure-store";

require('dotenv').config();

class About extends Component {

    constructor(props) {

        super(props);

        this.state = {
            isOpen: false,
            photoIndex: 0,
            teams: [
                {
                    name: 'Alif Hastono',
                    title: 'Director',
                    // phone:'+62811154396',
                    phone: '',
                    email:'alifhastono@abadisurvey.com',
                    image: alif,
                },
                {
                    name: 'Muhammad Faishal',
                    title: 'Vice President',
                    phone:'+6281319801898',
                    email:'m.faishal@abadisurvey.com',
                    image: faisal,
                },
                {
                    name: 'Ahmad Fauzi',
                    title: 'Senior Surveyor',
                    // phone:'+62811867486',
                    phone: '',
                    email:'ahmadfauzi@abadisurvey.com',
                    image: fauzi,
                },
                {
                    name: 'Pudjo Kristanto',
                    title: 'Senior Surveyor',
                    // phone:'+62811120435',
                    phone: '',
                    email:'pudjo.k@abadisurvey.com',
                    image: pudjo,
                },
                {
                    name: 'Muhammad Bilal S. Rizki',
                    title: 'Assistant VP Operation',
                    // phone:'+6282228965367',
                    phone: '',
                    email:'bilal@abadisurvey.com',
                    image: bilal,
                },
                {
                    name: 'Nathania Rismauli',
                    title: 'Office Executive',
                    // phone: '+62895332151015',
                    phone: '',
                    email: 'nathania@abadisurvey.com',
                    image: nathania,
                },
                {
                    name: 'Clarima Flourenza',
                    title: 'Finance',
                    // phone:'+6285718180455',
                    phone: '',
                    email:'clarimaflo@abadisurvey.com',
                    image: rima,
                },
                {
                    name: 'Rizqia Fitri Febriana',
                    title: 'Finance',
                    phone: '',
                    email: 'rizkiafitri@abadisurvey.com',
                    image: rizqia,
                },
                {
                    name: 'Anwar Hendarto',
                    title: 'Surveyor',
                    // phone:'+6281946419634',
                    phone: '',
                    email:'anwar@abadisurvey.com',
                    image: anwar,
                },
                {
                    name: 'Tomy Petir Andi Wijaya',
                    title: 'Surveyor',
                    // phone:'+6281298200222',
                    phone: '',
                    email:'tomywijaya@abadisurvey.com',
                    image: tomy,
                },
                {
                    name: 'Junor Maulana Ahmad',
                    title: 'Surveyor',
                    // phone:'+6281310479291',
                    phone: '',
                    email:'junor@abadisurvey.com',
                    image: junor,
                },
                {
                    name: 'Timothy Henry',
                    title: 'Surveyor',
                    // phone:'+6287881532028',
                    phone: '',
                    email:'timothyhry@abadisurvey.com',
                    image: timothy,
                },
                {
                    name: 'Alifian Aji Santoso',
                    title: 'Surveyor',
                    // phone:'+6281331111106',
                    phone: '',
                    email:'alifianajis@abadisurvey.com',
                    image: alifian,
                },
            ]
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        document.title = 'Abadi Cemerlang - Our Team';

    }


    handleChange (e, prop){

        this.setState({

            [prop]: e.target.value

        });

    };

    handleOpen = (row) => {

        this.setState({
            showDialog :true,
            idDelete: row.id,
            currDelete: row.title,
        })
    };

    handleClose = () => {
        this.setState({
            showDialog :false,
        })
    };


    handleGo = (link) => {

        history.push(link);
    };


    render() {
        return (
            <div>
                <section className="section-banner service" style={{backgroundImage: `url(${section})`}}>
                    <div className='container'>
                        <div className='row '>
                            <div className='col-md-10'>
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                <div className='content text-left'>
                                    <h1>OUR TEAM</h1>
                                    <p>We are proud of our team of motivated professionals, with highly qualified Naval Architects and Marine Engineering combined with technical expertise and practical experience, working together as a team supported by our administration and management support with backgrounds in their respective fields. </p>
                                    <p>We pay attention to our customers and make all efforts to improve our service to obtain maximum performance. We are aware our customers require immediate service and accuracy. We should welcome the opportunity to share our knowledge and expertise with your company to sharpen your competitive edge in a very dynamic business situation. If you need best in class execution, please contact us anytime whenever you wish.</p>
                               </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-content">
                    <div className='container'>
                        <div className='row mb-5 justify-content-center'>
                            <div className='col-md-10 text-center'>
                                <p className='mb-3'>We pay attention to our customers and make all efforts to improve our service to obtain maximum performance. We are aware our customers require immediate service and accuracy. We should welcome the opportunity to share our knowledge and expertise with your company to sharpen your competitive edge in a very dynamic business situation. If you need best in class execution, please contact us anytime whenever you wish. Our Surveyors are always at your disposal to be contacted 24 hours a day and 7 days a week the whole year.</p>
                            </div>
                        </div>
                        <div className='row '>
                            {this.state.teams.map((row,id) => (
                                <div className='col-md-4' key={id}>
                                    <div className='card-team'>
                                        <img src={row.image} className='img-team' alt="img-team" />
                                        <div className='team-detail'>
                                            <h3>{row.name}</h3>
                                            <small>{row.title}</small>
                                            <hr />
                                            {row.phone ? <p><PhoneIcon /> {row.phone}</p> : ''}
                                            <p><EmailIcon /> {row.email}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default About;
